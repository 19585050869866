import React, { useEffect } from 'react';
import { useLocation } from "react-router";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.css';

import loadable from '@loadable/component'
const ModalWrapper = loadable(() => import("./components/hooks/ModalWrapper"))
const ModalCookies = loadable(() => import("./components/pages/shared/modals/ModalCookies"))
const Home = loadable(() => import('./components/pages/Home'))
const LegalTerms = loadable(() => import('./components/pages/LegalTerms'))
const VuelosCharter = loadable(() => import('./components/pages/VuelosCharter'))
const Contact = loadable(() => import('./components/pages/Contact'))

function App() {
  return (
    <BrowserRouter>
      <ModalWrapper>
    
        <ScrollToTop/>
        <ModalCookies/>
    
        <Switch>
          <Route exact path={"/"} component={Home}/>
          <Route exact path={"/contact"} component={Contact}/>
          <Route exact path={"/charterFrom"} component={VuelosCharter}/>
          <Route exact path={"/legal"} component={LegalTerms}/>
        </Switch>
      </ModalWrapper>
    </BrowserRouter>
  );
}

export default App;


const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
  };